import React from "react"

import Layoutcs from "../../../components/layoutcs/layoutcs"
import SEO from "../../../components/seo"

import Container from 'react-bootstrap/Container'

import Bloglist from "../../../components/bloglist/bloglist"

import "../../index.scss"
import "../../blog.scss"

import ImgBlogPost from "../../../images/blogpost/moderni-technologie-pro-retail-audit.jpg"

const Blog = () => (

	<Layoutcs>
		<SEO title="Modrení technologie pro retail audit" description="V časopise Zboží&amp;Prodej vyšel článek, který pojednává o tématu retail-auditů a moderních technologiích, včetně naší auditní aplikace SPRINGaudit." />

		<Container className="ftrs-content pb-4 ftrs-blogpost">

			<h1 className="ftrs-h1">Modrení technologie pro retail audit</h1>
			<div className="ftrs-blogpost__date">20.01.2022</div>

			<img className="ftrs-blogpost__img" src={ImgBlogPost} alt="Modrení technologie pro retail audit" />

		</Container>

		<div className="ftrs-blogpost__text">
			<Container>

				<p>V časopise <a className="ftrs-blacklink" href="https://www.zboziaprodej.cz/wp-content/uploads/2021/12/zbozi_a_prodej_11-12_2021.pdf" target="_blank" rel="noreferrer">Zboží&amp;Prodej</a> vyšel článek, který pojednává o&nbsp;tématu retail-auditů a&nbsp;moderních technologiích, které pro&nbsp;něj lze využít, včetně naší <a className="ftrs-blacklink" href="https://www.springaudit.com" target="_blank" rel="noreferrer">auditní aplikace SPRINGaudit</a>.</p>
				
				<p>Náš senior consultant Aleš Holakovský se v&nbsp;článku vyjadřuje k&nbsp;následujícím tématům:</p>

				<ul>
					<li>šíře využitelnosti retail-auditů a zjištěných dat</li>
					<li>porovnání výhod a nevýhod realizace auditů externí agenturou v porovnání se svépomocným řešením auditů</li>
					<li>možnost pronájmu on-line reportingu Spring přizpůsobeného pro auditní projekty, včetně mobilní aplikace SPRINGaudit</li>
				</ul>

				<h2 className="ftrs-h2 mt-4 mb-4">Technologie umožňují získat cenná přesná data</h2>

				<p>Agentura Market Vision má k&nbsp;dispozici vlastní on-line reportingový systém přizpůsobený speciálně pro&nbsp;auditní projekty, včetně mobilní aplikace SprinGaudit s&nbsp;on-line reportingem v&nbsp;reálném čase, která je vhodná zejména na&nbsp;rychlé audity v&nbsp;oblasti retailu, ale zdaleka nejenom tam. „To&nbsp;umožňuje auditorům vyplňovat výstupy z&nbsp;auditu včetně fotodokumentace přímo na&nbsp;místě stejně jako našim klientům mít on-line přehled o&nbsp;vykonaných auditech s&nbsp;možností poslat k&nbsp;jednotlivým výstupům připomínky. Našim klientům nabízíme i&nbsp;možnost samostatného pronájmu tohoto našeho IT nástroje na&nbsp;realizaci auditů. Klient si od&nbsp;nás jednoduše pronajme aplikaci SprinGaudit, do&nbsp;které mu naskriptujeme jím požadovaný auditní formulář, provedeme na&nbsp;to navázané základní nastavení reportingu a&nbsp;vytvoříme uživatelské přístupy,“ konkretizuje Aleš Holakovský. Následně už si klient realizuje audity sám ve&nbsp;vlastní režii za&nbsp;pomoci svého vlastního personálu, pokud na&nbsp;to má interní kapacity.</p>

				<p>Více se dočtete <a className="ftrs-blacklink" href="https://www.marketvision.cz/admin/sites/default/files/attachment/330/710-zboziaprodej11-122021str52-54.pdf" target="_blank" rel="noreferrer">v&nbsp;kompletním článku</a>.</p>


			</Container>
		</div>

		<Container className="pt-4 pb-4">
			<Bloglist></Bloglist>
		</Container>

	</Layoutcs>

)

export default Blog